import { apiService } from './api';

export const loyaltyUrl = '/service/loyalty/api/loyalties';

export const loyaltyService = {
    activateMember: ({ code, memberId, membershipId }) =>
        apiService.put(
            `${loyaltyUrl}/%userId%/membership/${membershipId}/members/${memberId}/activation`,
            {
                code,
            },
        ),
    addMemberToMembership: subMemberId =>
        apiService.post(`${loyaltyUrl}/%userId%/membership`, { subMemberId }),
    checkMembershipAvailability: memberId =>
        apiService.get('/loyalties/checkMembershipAvailability', {
            params: {
                memberId,
            },
        }),
    confirmDeletionFromMembership: ({ membershipId, memberId }) =>
        apiService.put(
            `${loyaltyUrl}/%userId%/membership/${membershipId}/members/${memberId}/confirmation`,
        ),
    confirmMilesPaymentTransaction: ({ transactionId, ...data }) =>
        apiService.put(
            `${loyaltyUrl}/%userId%/miles-transactions/${transactionId}/confirmation3ds`,
            data,
        ),
    confirmMilesTransaction: ({ confirmationCode, transactionId }) =>
        apiService.put(
            `${loyaltyUrl}/%userId%/miles-transactions/${transactionId}/confirmation`,
            {
                confirmationCode: { code: confirmationCode },
            },
        ),
    createMilesPaymentTransaction: ({ transactionId, ...data }) =>
        apiService.post(
            `${loyaltyUrl}/%userId%/miles-transactions/${transactionId}/payment`,
            data,
        ),
    createMilesTransaction: data =>
        apiService.post(`${loyaltyUrl}/%userId%/miles-transactions`, data),
    createRecoveryMilesClaim: data =>
        apiService.post(`${loyaltyUrl}/%userId%/miles/claim`, data),
    declineInvitation: ({ memberId, membershipId }) =>
        apiService.put(
            `${loyaltyUrl}/%userId%/membership/${membershipId}/members/${memberId}/reject-invitation`,
        ),
    deleteMemberFromMembership: ({ memberId, membershipId, params }) =>
        apiService.delete(
            `${loyaltyUrl}/%userId%/membership/${membershipId}/members/${memberId}`,
            {
                params,
            },
        ),
    getActivateStatusPackages: () =>
        apiService.get(`${loyaltyUrl}/%userId%/miles/purchase-status-packages`),
    getCharities: () => apiService.get(`${loyaltyUrl}/charity-accounts`),
    getClaimLoyaltyCardPackages: () =>
        apiService.get(`${loyaltyUrl}/%userId%/packages/claim-loyalty-card`),
    getExpirationPackages: () =>
        apiService.get(`${loyaltyUrl}/%userId%/miles/expiration-packages`),
    getLoyalty: params => apiService.get(`${loyaltyUrl}/%userId%`, { params }),
    getOrders: () =>
        apiService.get(`${loyaltyUrl}/%userId%/loyalty-card/claims`),
    getProlongationPackages: () =>
        apiService.get(`${loyaltyUrl}/%userId%/miles/prolongation-packages`),
    getPurchasePackages: () =>
        apiService.get(`${loyaltyUrl}/%userId%/miles/purchase-packages`),
    getRetroClaims: params =>
        apiService.get(`${loyaltyUrl}/%userId%/retro-claims`, { params }),
    getTransactions: params =>
        apiService.get(`${loyaltyUrl}/%userId%/transactions`, { params }),
    getTransferPackages: () =>
        apiService.get(`${loyaltyUrl}/%userId%/miles/transfer-packages`),
    getUpgradePackages: () =>
        apiService.get(`${loyaltyUrl}/%userId%/miles/upgrade-packages`),
    rejectDeletionFromMembership: ({ membershipId, memberId }) =>
        apiService.put(
            `${loyaltyUrl}/%userId%/membership/${membershipId}/members/${memberId}/reject-deletion`,
        ),
    resendMilesTransaction: ({ transactionId, ...data }) =>
        apiService.post(
            `${loyaltyUrl}/%userId%/miles-transactions/${transactionId}/resend`,
            data,
        ),
    sendOrderClaimLoyaltyCard: data =>
        apiService.post(`${loyaltyUrl}/%userId%/loyalty-card/claims`, data),
    useVoucher: code =>
        apiService.post(`${loyaltyUrl}/%userId%/voucher`, { code }),
};

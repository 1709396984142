import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr, propOr } from 'ramda';
import { changeExpandedMileActions } from 'store/modules/miles';

import {
    MILES_ACTIVATE_STATUS_ID,
    MILES_BUY_ID,
    MILES_EXPIRATION_ID,
    MILES_PHYSICAL_CARD_ID,
    MILES_PROLONGATION_ID,
    MILES_STATUS_TRANSFER,
    MILES_TRANSFER_ID,
    MILES_UPGRADE_ID,
} from '../containers/ActionMiles/ids';
import { paymentTypes } from '../data';
import { isLoaded as getIsLoadedAirlines } from '../store/modules/airlines';
import { isLoaded as getIsLoadedCountries } from '../store/modules/countries';
import { isLoadedConfig as getIsLoadedLanguages } from '../store/modules/languages';
import { isLoaded as getIsLoadedLoyalty } from '../store/modules/loyalty';
import {
    activateStatus,
    buyLoyaltyCard,
    buy as buyMiles,
    expiration as expirationMiles,
    prolongation,
    transfer as transferMiles,
    upgrade,
} from '../store/modules/miles';
import { isLoaded as getIsLoadedProfile } from '../store/modules/profile';
import { isLoaded as getIsLoadedTransactions } from '../store/modules/transactions';
import { getSessionValue } from '../utils/storage';

const isClient = typeof window === 'object';

export function use3dsConfirmHandler({ pares, md, query, callbacks, cres }) {
    const [isPaymentProcess, setIsPaymentProcess] = useState(false);

    const dispatch = useDispatch();

    const isLoadedLanguages = useSelector(getIsLoadedLanguages);
    const isLoadedProfile = useSelector(getIsLoadedProfile);
    const isLoadedLoyalty = useSelector(getIsLoadedLoyalty);
    const isLoadedTransactions = useSelector(getIsLoadedTransactions);
    const isLoadedCountries = useSelector(getIsLoadedCountries);
    const isLoadedAirlines = useSelector(getIsLoadedAirlines);

    const isAllLoaded = useCallback(
        () =>
            isLoadedLanguages('global') &&
            isLoadedLanguages('profile') &&
            isLoadedLanguages('miles') &&
            isLoadedProfile &&
            isLoadedLoyalty &&
            isLoadedTransactions &&
            isLoadedCountries &&
            isLoadedAirlines,
        [
            isLoadedAirlines,
            isLoadedCountries,
            isLoadedLanguages,
            isLoadedLoyalty,
            isLoadedProfile,
            isLoadedTransactions,
        ],
    );

    useEffect(() => {
        if (
            !isClient ||
            isPaymentProcess ||
            !isAllLoaded() ||
            !md ||
            !(pares || cres)
        ) {
            return;
        }

        const paymentType = getSessionValue('paymentType');
        const paymentDetail = getSessionValue('paymentDetail') || {};

        const paymentParams = {
            secure: {
                ...paymentDetail,
                _3ds: {
                    ...propOr({}, '_3ds', paymentDetail),
                    paRes: pares || cres,
                },
            },
        };

        const handlePayment = (action, actionId) => {
            const callbackObj =
                Array.isArray(callbacks) &&
                callbacks.find(item => item.type === paymentType);

            if (callbackObj && callbackObj.beforeAction) {
                callbackObj.beforeAction();
            }

            dispatch(action(paymentParams));

            if (callbackObj && callbackObj.afterAction) {
                callbackObj.afterAction();
            }

            if (query && actionId) {
                query.action = actionId;
            }
        };

        if (md === pathOr(null, ['_3ds', 'md'], paymentDetail)) {
            switch (paymentType) {
                case paymentTypes.LOYALTY_BUY:
                    handlePayment(buyMiles, MILES_BUY_ID);
                    break;

                case paymentTypes.LOYALTY_EXPIRATION:
                    handlePayment(expirationMiles, MILES_EXPIRATION_ID);
                    break;

                case paymentTypes.LOYALTY_TRANSFER:
                    handlePayment(transferMiles, MILES_TRANSFER_ID);
                    break;

                case paymentTypes.LOYALTY_PROLONGATION:
                    handlePayment(prolongation, MILES_PROLONGATION_ID);
                    break;

                case paymentTypes.LOYALTY_UPGRADE:
                    handlePayment(upgrade, MILES_UPGRADE_ID);
                    break;

                case paymentTypes.LOYALTY_CARD:
                    handlePayment(buyLoyaltyCard, MILES_PHYSICAL_CARD_ID);
                    break;
                case paymentTypes.LOYALTY_ACTIVATE_STATUS:
                    handlePayment(activateStatus, MILES_ACTIVATE_STATUS_ID);
                    break;
                case paymentTypes.LOYALTY_STATUS_TRANSFER:
                    sessionStorage.setItem(
                        'paymentDetail',
                        JSON.stringify(paymentParams.secure),
                    );
                    dispatch(
                        changeExpandedMileActions({
                            id: MILES_STATUS_TRANSFER,
                        }),
                    );
                    break;
                default:
                    break;
            }

            setIsPaymentProcess(true);
        }
    }, [
        isPaymentProcess,
        isAllLoaded,
        md,
        pares,
        callbacks,
        dispatch,
        query,
        cres,
    ]);
}

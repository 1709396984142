export const MILES_ACTIVATE_STATUS_ID = 'activateStatus';
export const MILES_ACTIVATE_STATUS_NOTIFICATION = 'activateStatusNotification';
export const MILES_RECOVER_ID = 'claimmiles';
export const MILES_BUY_ID = 'purchasemiles';
export const MILES_TRANSFER_ID = 'transfermiles';
export const MILES_CHARITY_ID = 'charity';
export const MILES_CERTIFICATE_ID = 'gift';
export const MILES_PROLONGATION_ID = 'prolongation';
export const MILES_EXPIRATION_ID = 'expire';
export const MILES_UPGRADE_ID = 'upgrade';
export const MILES_PHYSICAL_CARD_ID = 'physicalCard';
export const MILES_STATUS_TRANSFER = 'statusTransfer';

export const getScrollId = id => `scrollTo-${id}`;

export const paymentTypes = {
    LOYALTY_ACTIVATE_STATUS: 'activateStatus',
    LOYALTY_BUY: 'buy',
    LOYALTY_CARD: 'claimLoyaltyCard',
    LOYALTY_EXPIRATION: 'expiration',
    LOYALTY_PROLONGATION: 'prolongation',
    LOYALTY_TRANSFER: 'transfer',
    LOYALTY_UPGRADE: 'upgrade',
    LOYALTY_STATUS_TRANSFER: 'status-transfer',
};

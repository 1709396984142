import { LOYALTY_STATUS_OPENED, LOYALTY_STATUS_SUSPENDED } from 'consts';
import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import {
    compose,
    filter,
    find,
    join,
    map,
    path,
    pathOr,
    pick,
    propOr,
    replace,
    split,
    toLower,
    toUpper,
} from 'ramda';
import { createSelector } from 'reselect';
import { RULE_MILES_ACTIVATE_STATUS } from 'utils/allowFeature';

import { notificationHandlers } from './helpers';

import { memberStatus, memberType } from '../../../data/membership';
import { isNumber } from '../../../utils';
import { isFading } from '../../../utils/fadingTime';
import { getDictionary, getLocale } from '../languages';

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const DD_MMM_YYYY = DATE_FORMATS.DD.MMM.YYYY;
const { ll } = DATE_FORMATS;

const formatStatus = status =>
    compose(
        join(' '),
        map(val => `${toUpper(val.charAt(0))}${val.slice(1)}`),
        split(' '),
        toLower,
        replace(/[_-]/g, ' '),
    )(status);

export const isInitialized = state => state.loyalty.isInitialized;
export const isLoaded = state => isFading(state.loyalty.fadingTime);
export const isLoading = state =>
    pathOr(false, ['loyalty', 'isLoading'], state);
export const isError = state => state.loyalty.isError;

export const isSync = state => state.loyalty.isSync;
export const getMembershipID = state => state.loyalty.memberId;
export const getEmail = state => state.loyalty.email;
export const getPhoneNumber = state => state.loyalty.phoneNumber;

export const getLoyalLevel = createSelector(
    pathOr('classic', ['loyalty', 'status']),
    status => formatStatus(status),
);

export const getLoyaltyLevel = createSelector(
    pathOr('classic', ['loyalty', 'status']),
    status => status,
);

export const getNonFormattedLoyalLevel = pathOr('classic', [
    'loyalty',
    'status',
]);

export const getStatusExpiration = state => state.loyalty.statusExpiration;
export const getStatusExpirationDate = createSelector(
    getStatusExpiration,
    date => (date ? moment(date, YYYY_MM_DD) : null),
);

// balances
const getBalanceValue = (state, value) => {
    const balanceValue = path(['loyalty', 'balances', value], state);

    if (value === 'businessUpgrade') {
        return isNumber(balanceValue) ? balanceValue : null;
    }

    return parseInt(balanceValue, 10) || 0;
};

export const getMiles = state => getBalanceValue(state, 'redemption');
export const getMilesStatus = state => getBalanceValue(state, 'qualifying');
export const getFlights = state => getBalanceValue(state, 'flights');
export const getBusinessUpgrade = state =>
    getBalanceValue(state, 'businessUpgrade');

export const getBurningPoints = state =>
    path(['loyalty', 'burningPoints'], state);

// account
const getAccountValue = (state, value) =>
    path(['loyalty', 'account', value], state);

export const getStatusType = state => getAccountValue(state, 'status');

export const formatDate = date => moment(date).format(DD_MMM_YYYY);

export const getLoyaltyCreated = state => getAccountValue(state, 'createdAt');
export const getLoyaltyCreatedDate = createSelector(
    getLoyaltyCreated,
    createdAt => formatDate(createdAt),
);

export const getLoyaltyUpdated = state => getAccountValue(state, 'updatedAt');
export const getLoyaltyUpdatedDate = createSelector(
    getLoyaltyUpdated,
    updatedAt => formatDate(updatedAt),
);

export const loyaltyIsActive = state =>
    ['ACTIVE', 'SUSPENDED'].includes(getStatusType(state));
export const loyaltyIsSuspend = state =>
    getStatusType(state) === LOYALTY_STATUS_SUSPENDED;

export const getIsCleanNewUser = state =>
    getStatusType(state) === LOYALTY_STATUS_OPENED &&
    moment(getLoyaltyCreated(state)).add(6, 'months').isAfter(moment(), 'day');

// next level requirements
const getNextLevelValue = (state, value) =>
    path(['loyalty', 'nextLevelRequirements', value], state);

export const getNextLevelRequirements = createSelector(
    pathOr({}, ['loyalty', 'nextLevelRequirements']),
    nextLevelRequirements => nextLevelRequirements,
);

export const getNextLevel = state =>
    getNextLevelValue(state, 'nextLevel') || '';

export const hasPrepaid = createSelector(
    getFlights,
    getMilesStatus,
    getLoyalLevel,
    (flights, miles, status) => {
        const dataByType = {
            CLASSIC: {
                flightsPercent: 20 * flights,
                milesPercent: 20000 * miles,
            },
            DEFAULT: {
                flightsPercent: 0.1,
                milesPercent: 0.1,
            },
            GOLD: {
                flightsPercent: 25 * (flights - 50),
                milesPercent: 25000 * (miles - 50000),
            },
            SILVER: {
                flightsPercent: 30 * (flights - 20),
                milesPercent: 30000 * (miles - 20000),
            },
        };

        const { milesPercent, flightsPercent } =
            dataByType[status] || dataByType.DEFAULT;

        return 1 / milesPercent > 0.8 || 1 / flightsPercent > 0.8;
    },
);

export const getJwt = pathOr('', ['loyalty', 'jwt', 'token']);

export const isJwtInitialized = state => state.loyalty.jwt.isInitialized;
export const isJwtLoading = state => state.loyalty.jwt.isLoading;
export const isJwtError = state => state.loyalty.jwt.isError;

export const getIsClosedMilesNotification = state =>
    state.loyalty.isClosedMilesNotification;

// Group account
const getName = (names, locale) =>
    names.find(item => item.lang === locale) ||
    names.find(item => item.lang === 'en') ||
    names.find(item => item.lang === 'ru');

export const formatMember = locale => member => {
    const loyaltyProfile = propOr({}, 'loyaltyProfile', member);
    const name = getName(loyaltyProfile.names, locale);

    return {
        birthday: loyaltyProfile.birthday
            ? moment(loyaltyProfile.birthday, YYYY_MM_DD).format(ll)
            : '__',
        expirationDate: loyaltyProfile.expirationDate
            ? moment(member.expirationDate, YYYY_MM_DD).format(ll)
            : '__',
        gender: pathOr('', ['loyaltyProfile', 'gender'], member),
        initials: name
            ? `${toUpper(name.firstName[0])}${toUpper(name.lastName[0])}`
            : '',
        memberId: loyaltyProfile.memberId,
        name: name ? `${name.firstName} ${name.lastName}` : '__',
        startedAt: moment(member.startedAt, YYYY_MM_DD).format(ll),
        ...pick(['id', 'type', 'status', 'externalMemberId'], member),
    };
};

export const selectLoyalty = state => state.loyalty;

export const selectMembership = createSelector(
    selectLoyalty,
    loyalty => loyalty.membership,
);

export const getIsMembershipCreationAvailable = createSelector(
    selectLoyalty,
    loyalty => loyalty.membershipCreationAvailable,
);

export const getMembershipConfirmationCode = createSelector(
    selectLoyalty,
    loyalty => loyalty.membershipConfirmationCode,
);

export const getGroupMemberId = createSelector(
    selectMembership,
    membership => membership.id,
);

export const getMembers = createSelector(
    selectMembership,
    getLocale,
    (membership, locale) => {
        return compose(
            map(formatMember(locale)),
            filter(member => member.status !== memberStatus.DELETED),
        )(membership.members || []);
    },
);

export const getCurrentMember = createSelector(
    getMembers,
    getMembershipID,
    (members, id) => find(member => member.memberId === id, members),
);

export const getIsMemberOfGroup = createSelector(getCurrentMember, member => {
    return (
        member &&
        [
            memberStatus.PENDING,
            memberStatus.ACTIVE,
            memberStatus.DELETION_PENDING,
        ].includes(member.status)
    );
});

export const getMembershipAdmin = state => state.loyalty.membershipAdmin;

export const getIsMemberHead = createSelector(
    getCurrentMember,
    member => member && member.type === memberType.HEAD,
);
export const getIsMemberSub = createSelector(
    getCurrentMember,
    member => member && member.type === memberType.SUB,
);

const getNotification = createSelector(
    selectMembership,
    membership => membership.notification,
);

export const getMembershipNotification = createSelector(
    [getNotification, getMembershipAdmin, getDictionary],
    (notification, admin, dictionary) => {
        if (notification && notification.handler) {
            const { handler, ...notificationProps } = notification;

            return {
                ...notificationProps,
                ...notificationHandlers[handler]({
                    admin: admin || {},
                    dictionary,
                }),
            };
        }

        return notification;
    },
);

export const getIsConfirming = createSelector(
    selectMembership,
    membership => membership.isConfirming,
);

export const getIsDeclining = createSelector(
    selectMembership,
    membership => membership.isDeclining,
);
export const getCardInfo = createSelector(
    selectMembership,
    membership => membership.cardInfo,
);

export const getMemberDeleting = state =>
    pathOr({}, ['loyalty', 'memberDeleting'], state);

export const getAvailableMilesOperations = createSelector(
    state => state.loyalty.availableMilesOperations,
    availableOperations => ({
        isActivateStatus:
            availableOperations.includes('PURCHASE_STATUS') &&
            RULE_MILES_ACTIVATE_STATUS,
        isExpiration: availableOperations.includes('EXPIRATION'),
        isProlongation: availableOperations.includes('PROLONGATION'),
        isPurchase: availableOperations.includes('PURCHASE'),
        isTransfer:
            availableOperations.includes('TRANSFER') ||
            availableOperations.includes('TRANSFER_CHARITY'),
        isUpgrade: availableOperations.includes('UPGRADE'),
        isStatusTransfer: availableOperations.includes('TRANSFER_STATUS_MILES'),
    }),
);

// OLD
// export const getFull = state => state.loyaltygetStatusType
export const hasExpiringStatus = state => state.loyalty.statusExpiried; // TODO remove
export const getExpiringStatus = state => state.loyalty.statusExpire; // TODO remove
export const hasExpiringMiles = state => state.loyalty.expiringMiles > 0; // TODO fix
export const getExpiringMiles = state =>
    parseInt(state.loyalty.expiringMiles, 10); // TODO fix
